<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="certificate-generate-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.released_order') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" @click="pdfExport" class="">
                {{  $t('globalTrans.export_pdf') }}
            </b-button> -->
              <b-button type="button" variant="primary" class="mr-2" @click="pdfExport">{{ $t('globalTrans.print') }}</b-button>
              <b-button type="button" variant="primary" class="mr-2" @click="wordDownloadExport">{{ $t('elearning_tim.download_word') }}</b-button>
              <b-button type="button" variant="primary" class="mr-2" @click="pdfDownloadExport">{{ $t('elearning_tim.download_pdf') }}</b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <div id="printData" class="genrate-certificate">
                <div class="body-print" style="padding: 20px;">
                  <b-row>
                      <b-col>
                          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="org_id">
                          </list-report-head>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="top-height m-0" style="padding-left: 10px; padding-right: 10px">
                      <table border="0" class="mt-3 tabletwo"  style="width:100%">
                        <tbody>
                          <tr v-if="releaseDescription">
                            <th style="width: 8%"  class="text-left memo-no" >{{ $t('elearning_tim.memorial_no') }}:</th>
                            <td style="width: 39%" class="text-left">{{ releaseDescription.memorial_no }}</td>
                            <th style="width: 35%" class="text-right" >{{ $t('globalTrans.date') }}:</th>
                            <td style="width: 15%" class="text-left">{{ releaseDescription.date | dateFormat }} {{ $t('elearning_tim.ad') }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" class="tabletwo" style="width:100%;">
                        <tbody>
                          <tr>
                            <td style="width: 6%"  class="text-left" >{{ $t('elearning_tim.subject') }}:</td>
                            <td style="width: 94%" class="text-left"><b style="font-size: 22px;">“{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}” {{ $t('elearning_tim.subject_end') }}</b></td>
                          </tr>
                        </tbody>
                      </table><br>
                      <!-- <template>
                          <b>{{ releasedate.training_start_date | dateFormat }}</b> {{ $t('elearning_tim.release1') }} <b>{{ releasedate.training_end_date | dateFormat }}</b> {{ $t('elearning_tim.release2') }}
                          <b>“{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}”</b> {{ $t('elearning_tim.release3') }} <b>{{ releasedate.training_end_date | dateFormat }}</b> {{ $t('elearning_tim.release4') }}
                      </template> -->
                        <div class="main-body">
                          {{ org_data_name ? ($i18n.locale === 'bn' ? org_data_name_bn : org_data_name) + ', ' : '' }} {{ org_id !== 12 ? ($i18n.locale === 'bn' ? address_bn : address) : $t('elearning_tim.release20') }}
                          {{ $t('elearning_tim.past') }}{{ releasedate.training_start_date | dateFormat }} {{ $t('elearning_tim.release1') }} {{ releasedate.training_end_date | dateFormat }} {{ $t('elearning_tim.release2') }} <b>“{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}”</b>
                          {{ $t('elearning_tim.release22') }} {{ $t('elearning_tim.release3') }} {{ releasedate.training_end_date | dateFormat }} {{ $t('elearning_tim.release4') }}
                        </div>
                      </b-col>
                  </b-row>
                  <b-overlay :show="load">
                    <b-row id="form" class="pt-4">
                      <div class="col-md-12">
                        <!-- <fieldset class="p-2 w-100"> -->
                          <!-- <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.details')}}</legend> -->
                          <div class="table-responsive borders">
                            <table class="tg" style="width:100%;" border="1">
                              <thead class="thead">
                                <tr>
                                  <th class="text-center tg-0lax" style="width:5%">{{$t('elearning_tim.sl_no')}}</th>
                                  <th class="text-center tg-0lax" style="width:25%">{{$t('elearning_tim.officer_name')}}</th>
                                  <!-- <th class="text-center tg-0lax">{{$t('globalTrans.organization')}}</th> -->
                                  <th class="text-center tg-0lax" style="width:25%">{{$t('elearning_tim.designation')}}</th>
                                  <th class="text-center tg-0lax" style="width:45%">{{$t('elearning_tim.workplace')}}</th>
                                </tr>
                              </thead>
                              <tr v-for="(data, index) in dataList" :key="index">
                                <td class="text-center tg-0lax" style="padding-left: 5px;padding-top: 3px;padding-bottom: 3px;margin: 0;">
                                  {{ $n(index + 1) }}
                                </td>
                                <td class="text-left tg-0lax" style="padding-left: 5px;padding-top: 3px;padding-bottom: 3px;margin: 0;">
                                  {{ $i18n.locale === 'bn' ? data.name_bn : data.name }}
                                </td>
                                <!-- <td class="text-left tg-0lax" style="padding-left: 5px;padding-top: 3px;padding-bottom: 3px;margin: 0;">
                                  {{ $i18n.locale === 'bn' ? data.org_name_bn : data.org_name }}
                                </td> -->
                                <td class="text-left tg-0lax" style="padding-left: 5px;padding-top: 3px;padding-bottom: 3px;margin: 0;">
                                  <!-- {{ ($i18n.locale==='bn') ? data.designation_name_bn : data.designation_name }} -->
                                  <span v-if="data.designation_id && data.not_here_designation == 0 && data.p_profession_type == 1">
                                    {{ ($i18n.locale==='bn') ? data.designation_name_bn : data.designation_name }}
                                  </span>
                                  <span v-else>
                                    {{ ($i18n.locale==='bn') ? data.designation_bn : data.designation_en }}
                                  </span>
                                </td>
                                <td class="text-left tg-0lax" style="padding-left: 5px;padding-top: 3px;padding-bottom: 3px;margin: 0;">
                                  <!-- {{ (($i18n.locale==='bn') ? data.office_name_bn : data.office_name) }}, {{ $i18n.locale === 'bn' ? data.org_name_bn : data.org_name }} -->
                                  <span v-if="data.p_profession_type == 1 && data.not_here_office == 0 && data.professional_office_id">{{ getOfficeName(data.professional_office_id) + getOrgName(data.professional_org_id) }}</span>
                                  <span v-else-if="data.p_profession_type == 1 && data.not_here_office == 1">{{ ($i18n.locale=='bn') ? (data.p_office_name_bn + '') : (data.p_office_name) }}</span>
                                  <span v-else>{{ ($i18n.locale=='bn') ? data.p_office_name_bn : data.p_office_name }}</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <br/>
                        <!-- </fieldset> -->
                      </div>
                    </b-row>
                    <div v-if="releaseDescription">
                      <table class="mt-3 tabletwo" style="width:100%;" border="0">
                          <tr v-if="releaseDescription.personal_info">
                              <td style="width:70%"></td>
                              <td style="width:30%; text-align:center">
                                  <div v-html="releaseDescription.personal_info"></div>
                              </td>
                          </tr>
                          <!-- <tr v-if="releaseDescription.description">
                              <td style="width:100%; text-align:left" colSpan="2">
                                  <div v-html="releaseDescription.description"></div>
                              </td>
                          </tr> -->
                      <!-- <div class="row col-lg-12">
                          <div class="col-lg-8"></div>
                          <div class="col-lg-4 text-center">
                            <span v-html="releaseDescription.personal_info"></span>
                          </div>
                      </div> -->
                      </table>
                      <table class="mt-3" style="width:100%;" border="0">
                        <tr>
                          <td>
                            <div v-html="releaseDescription.description"></div>
                          </td>
                        </tr>
                      </table>
                      <table class="mt-3" style="width:100%;" border="0">
                        <tr v-if="releaseDescription.description_footer">
                          <td style="width:70%"></td>
                          <td style="width:30%; text-align:center">
                              <div v-html="releaseDescription.description_footer"></div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </b-overlay>
                </div>
              </div>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{ dataList }}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.genrate-certificate p {
  margin-bottom: 0px !important;
}
.genrate-certificate td.text-center.tg-0lax {
  padding: 0px !important;
}
.genrate-certificate th.text-center.tg-0lax {
  padding: 0px !important;
}
.main-body {
  line-height: 32px;
  font-size: 18px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTrainingRe.vue'
// import ExportPdf from './export_pdf_details'
import { certificateDetails, wordExport, releaseOrderDownload } from '../../api/routes'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)

export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      title_en: '',
      title_bn: '',
      date: '',
      date_bn: '',
      startDate: '',
      startDate_bn: '',
      endDate: '',
      endDate_bn: '',
      designation: '',
      designation_bn: '',
      designation_name: '',
      designation_name_bn: '',
      org_id: 0,
      load: false,
      org_data_name: '',
      org_data_name_bn: '',
      address: '',
      address_bn: '',
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      data: [],
      dataList: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      releaseDescription: [],
      releasedate: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      pageStyle: {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        autoClose: false,
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          `${trainingElearningServiceBaseUrl}/cer-details.css`
        ]
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    convertToBanglaNumbers (input) {
      function toBanglaNumber (match) {
        const numberMap = {
          0: '০',
          1: '১',
          2: '২',
          3: '৩',
          4: '৪',
          5: '৫',
          6: '৬',
          7: '৭',
          8: '৮',
          9: '৯'
        }
        return match.split('').map(char => numberMap[char] || char).join('')
      }
      return input.replace(/[0-9]/g, toBanglaNumber)
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn + ', ' : ''
      } else {
        return office !== undefined ? office.text_en + ', ' : ''
      }
    },
    getOrgName (id) {
        const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.abbreviation_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.abbreviation : ''
        }
    },
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
          this.releaseDescription = []
        } else {
            this.honorariumSheet = result.data[0]
            this.releaseDescription = result.dataMain
            const date = result.dataMain.date.split('-')
            // let monthId = ''
            // monthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(date[1]))
            this.date = date[2] + '/' + date[1] + '/' + date[0]
            this.date_bn = this.convertToBanglaNumbers(date[2]) + '/' + this.convertToBanglaNumbers(date[1]) + '/' + this.convertToBanglaNumbers(date[0], { useGrouping: false })
            this.releasedate = result.datapublication
            const startDate = this.releasedate.training_start_date.split('-')
            this.startDate = startDate[2] + '/' + startDate[1] + '/' + startDate[0]
            this.startDate_bn = this.convertToBanglaNumbers(startDate[2]) + '/' + this.convertToBanglaNumbers(startDate[1]) + '/' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
            const endDate = this.releasedate.training_end_date.split('-')
            this.endDate = endDate[2] + '/' + endDate[1] + '/' + endDate[0]
            this.endDate_bn = this.convertToBanglaNumbers(endDate[2]) + '/' + this.convertToBanglaNumbers(endDate[1]) + '/' + this.convertToBanglaNumbers(endDate[0], { useGrouping: false })
            this.getCustomDataZeroList(result.data[0])
            this.dataList = this.getCustomDataList(result.data)
            this.org_id = result.data[0].org_id
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(result.data[0].org_id))
            if (typeof orgObj !== 'undefined') {
              this.org_data_name = orgObj.text_en
              this.org_data_name_bn = orgObj.text_bn
              this.address = orgObj.address
              this.address_bn = orgObj.address_bn
            } else {
              this.org_data_name = ''
              this.org_data_name_bn = ''
              this.address = ''
              this.address_bn = ''
            }
            // const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainee')
            // this.trainees = this.getCustomDataList(trainees)
            // this.materials = result.matherial
        }
        this.load = false
      }
    },
    // pdfExport () {
    //   const reportTitle = this.$t('elearning_tim.released_order') + ' ' + this.$t('globalTrans.details')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.dataList, this.releaseDescription, this.releasedate)
    // },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              this.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
              this.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.abbreviation
              orgData.org_name_bn = orgObj.abbreviation_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
          return Object.assign({}, item, orgData, desigData, officeData)
        })
        return listData
    },
    printID () {
      this.$htmlToPaper('printData', this.pageStyle)
    },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.title_en = trainingTitleObj.text_en
            this.title_bn = trainingTitleObj.text_bn
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      },
    async wordDownloadExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.$route.query.circilarMemoNo,
        title_en: this.title_en,
        title_bn: this.title_bn,
        date: this.date,
        date_bn: this.date_bn,
        startDate: this.startDate,
        startDate_bn: this.startDate_bn,
        endDate: this.endDate,
        endDate_bn: this.endDate_bn,
        org_id: this.org_id,
        org_data_name: this.org_data_name,
        org_data_name_bn: this.org_data_name_bn,
        address: this.address,
        address_bn: this.address_bn,
        designation_name: this.designation_name,
        designation_name_bn: this.designation_name_bn
      }

      const params = Object.assign(
        {
          request_type: 'word',
          local: this.$i18n.locale,
          org_id: this.orgId,
          id: this.$route.query.id
        },
        sendData
      )

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, wordExport, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'nata-release-order.docx' // Set the desired filename with .docx extension for Word documents

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async pdfExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.$route.query.circilarMemoNo,
        title_en: this.title_en,
        title_bn: this.title_bn,
        date: this.date,
        date_bn: this.date_bn,
        startDate: this.startDate,
        startDate_bn: this.startDate_bn,
        endDate: this.endDate,
        endDate_bn: this.endDate_bn,
        org_id: this.org_id,
        org_data_name: this.org_data_name,
        org_data_name_bn: this.org_data_name_bn,
        address: this.address,
        address_bn: this.address_bn,
        designation: this.designation_name,
        designation_bn: this.designation_name_bn
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId, id: this.$route.query.id }, sendData)

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, releaseOrderDownload, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfDownloadExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.$route.query.circilarMemoNo,
        title_en: this.title_en,
        title_bn: this.title_bn,
        date: this.date,
        date_bn: this.date_bn,
        startDate: this.startDate,
        startDate_bn: this.startDate_bn,
        endDate: this.endDate,
        endDate_bn: this.endDate_bn,
        org_id: this.org_id,
        org_data_name: this.org_data_name,
        org_data_name_bn: this.org_data_name_bn,
        address: this.address,
        address_bn: this.address_bn,
        designation: this.designation_name,
        designation_bn: this.designation_name_bn
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId, id: this.$route.query.id }, sendData)

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, releaseOrderDownload, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/pdf'
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'nata-release-order.pdf' // Set the desired filename

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
